@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

@mixin lightmode() {
  // General website colors.
  --text-color: #2e3440;
  --link-color: #5e81ac;
  --link-color-hover: #8fbcbb;
  --background-color: #ffffff;
  --image-title-color: #4c566a;
  --image-title-transparent-background: rgba(235,239,245,0.6);

  // Navbar colors.
  --navbar-top-background-color: #eceff4;
  --navbar-bottom-background-color: #ffffff;
  --navbar-font-color: #4c566a;
  --navbar-title-firstname-color: #2e3440;
  --navbar-title-lastname-color: #4c566a;
  --active-color: #3b4252;
  --color-picker: #4c566a;

  // Icon colors.
  --email-icon-color: #154734;
  --github-icon-color: #171515;
  --resume-icon-color: #bf616a;
  --linkedin-icon-color: #0e76a8;
}
@mixin darkmode() {
  // General website colors.
  --text-color: #d8dee9;
  --link-color: #a3be8c;
  --link-color-hover: #b48ead;
  --background-color: #2e3440;
  --image-title-color: #4c566a;
  
  // Navbar colors.
  --navbar-top-background-color: #4c566a;
  --navbar-bottom-background-color: #2e3440;
  --navbar-font-color: #e5e9f0;
  --navbar-title-firstname-color: #eceff4;
  --navbar-title-lastname-color: #d8dee9;
  --active-color: #e5e9f0;
  --color-picker: #eceff4;

  // Icon colors.
  --email-icon-color: #a3c1ad;
  --github-icon-color: #ebeaea;
  --resume-icon-color: #d08770;
  --linkedin-icon-color: #0e76a8;
}

body {
  font-family: 'Didact Gothic', sans-serif;
  @include lightmode();
  &.is_dark {
      @include darkmode();
   }
  @media (prefers-color-scheme: dark) {
      @include darkmode();
      &.is_dark {
          @include lightmode();
      }
  }

  // General website colors.
  background-color: var(--background-color);
  color: var(--text-color);
  a { color: var(--link-color); }
  a:hover { color: var(--link-color-hover); }
  .App {
    text-align: center;
    width: 100%;
    height: 100%;
  }
  .image-title {
    position: absolute; top: 50%; left: 50%; 
    border-radius: 10px;
    background: var(--image-title-transparent-background); padding: 10px;
    transform: translate(-50%);
    color: var(--image-title-color);
  }
  .center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  // Navbar colors.
  .navbar-top-background { background-color: var(--navbar-top-background-color); }
  .navbar-bottom-background { background-color: var(--navbar-bottom-background-color); }
  .navbar-font { color: var(--navbar-font-color); }
  .navbar-title-name { background: linear-gradient(to right, var(--navbar-title-firstname-color) 54.545454%, var(--navbar-title-lastname-color) 45.454546%); background-clip: text; -webkit-text-fill-color: transparent }
  .inactive { border-bottom: 2px solid transparent; display: inline; }
  .active { border-bottom: 2px solid var(--active-color); display: inline; }
  .color-picker-icon { fill: var(--color-picker); }
  .color-picker-font { color: var(--color-picker); }

  // Icon colors.
  .email-icon { fill: var(--email-icon-color); }
  .github-icon { fill: var(--github-icon-color); }
  .resume-icon { fill: var(--resume-icon-color); }
  .linkedin-icon { fill: var(--linkedin-icon-color); }
}