body {
  margin: 0;
  padding: 0;
  height: 100%; 
  width: 100%;
}

html {
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

#root {
  height: 100%; }
